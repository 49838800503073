import React from "react";
import { Text, TextProps } from "rebass/styled-components";

// Overwrites the rebass Text component so we don't have to set
// variant="body" on all our standard text blocks

export default (props: TextProps) => (
  <Text variant="body" {...props as any}>
    {props.children}
  </Text>
);
