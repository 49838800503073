import { createGlobalStyle } from "styled-components";
import merge from "lodash.merge";
import reset from "styled-reset";
import preset from "@rebass/preset";

export const avicennaTheme = merge(preset, {
  colors: {
    primaryLightest: "#E6EEEE",
    primaryLightestBg: "rgba(6, 92, 93, 0.17)",
    primaryLight: "#377C7D",
    primaryLightBg: "rgba(6, 92, 93, 0.30)",
    primary: "#065C5D",
    primaryDark: "#04494A",
    secondary: "#C2D100",
    secondarylightBg: "rgba(194, 209, 0, 0.3)",
    secondaryLight: "#DAE366",
    errorLight: "#FDE9EA",
    errorDark: "#A00D19",
    text: "#414D55",
    white: "#FFFFFF",
    transparent: "transparent",
  },
  fonts: {
    body: "Gotham-Light",
    heading: "Gotham-Light",
    bold: "Gotham-Bold",
    medium: "Gotham-Medium"
  },
  fontWeights: {
    body: 400,
    heading: 300,
    bold: 700,
  },
  breakpoints: ["700px", "1300px"],
  radii: {
    default: 8,
    small: 4,
    medium: 8,
    large: 16,
    largest: 64,
  },
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "white",
    },
    display: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7],
    },
    body: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      color: "text",
    },
  },
  variants: {
    avatar: {
      width: "avatar",
      height: "avatar",
      borderRadius: "circle",
    },
    card: {
      p: 2,
      bg: "background",
      boxShadow: "card",
    },
    bigLink: {
      fontFamily: "bold",
      fontSize: [1, 1, '15px'],
      textDecoration: "none",
      textTransform: "uppercase",
      cursor: "pointer",
      letterSpacing: '-0.19px',
      display: "inline-block",
      ":hover": {
        textDecoration: "underline",
      },
    },
  },
});

export default createGlobalStyle`
  ${reset};
  @media
  screen and (max-width: 700px){

    html,
    body{
      width:100%;
      overflow-x:hidden;
    }

  }
`;
